.grid_wrapper {
  @apply grid grid-rows-3 grid-cols-4 h-full gap-2;

  .viewer {
    @apply col-span-2 row-span-2;
  }

  .viewer_full {
    @apply col-span-2 row-span-4;
  }

  .identity {
    @apply col-span-2 row-span-1;
  }

  .photo {
    @apply col-span-1 row-span-1;

    div {
      display: flex;
      justify-content: center;

      img {
        max-height: 190px;
      }
    }
  }
}
