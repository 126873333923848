/*** Old stylings ***/
.btn-danger {
  @apply bg-red-500 text-white;
}

.btn-secondary {
  @apply bg-gray-300 text-gray-700;
}

.btn-green {
  @apply bg-green-500 text-white;
}

.btn-danger:hover {
  @apply bg-red-700 text-white;
}

/*** End old stylings ***/

/*** Sizes ***/
.btn {
  @apply font-bold py-2 px-4 rounded flex items-center shadow;

  border-radius: 6px;
}

.btn.borderless {
  background-color: transparent !important;
}

.btn-md {
  @apply py-[10px] px-10;
}

.btn-lg {
  @apply py-4 px-12;
}

/*** End Sizes ***/

/*** Primary color ***/
.btn-blue {
  @apply bg-blue text-white;

  &.borderless {
    @apply text-blue border border-blue;
  }

  &.ghost {
    @apply text-blue;

    background-color: unset;
    box-shadow: none;
  }

  &:hover {
    background-color: #0499d0;

    &.borderless {
      color: #0499d0;
      border-color: #0499d0;

      @apply border;
    }

    &.ghost {
      color: #0499d0;
      background-color: unset;
      box-shadow: none;
    }
  }

  &:active {
    background-color: #028dc1;

    &.borderless {
      color: #028dc1;
      border-color: #028dc1;

      @apply border;
    }

    &.ghost {
      color: #028dc1;
      background-color: unset;
      border: 1px solid #028dc1;
      box-shadow: none;
    }
  }

  &:disabled {
    background-color: rgba(0, 163, 224, 0.3);

    &.borderless {
      color: rgba(0, 163, 224, 0.3);
      background-color: rgba(0, 163, 224, 0.3);
    }

    &.ghost {
      color: rgba(0, 163, 224, 0.3);
      background-color: unset;
      border: 1px solid rgba(0, 163, 224, 0.3);
      box-shadow: none;
    }
  }
}

/*** End Primary color ***/

/*** Neutral color ***/
.btn-neutral {
  background-color: #9098a1;

  @apply text-white;

  &.borderless {
    color: #9098a1;
    border-color: #9098a1;

    @apply border;
  }

  &.ghost {
    color: #9098a1;
    background-color: unset;
    box-shadow: none;
  }

  &:hover {
    background-color: #858c94;

    &.borderless {
      color: #858c94;
      border-color: #858c94;

      @apply border;
    }

    &.ghost {
      color: #858c94;
      background-color: unset;
      box-shadow: none;
    }
  }

  &:active {
    background-color: #798087;

    &.borderless {
      color: #798087;
      border-color: #798087;

      @apply border;
    }

    &.ghost {
      color: #798087;
      background-color: unset;
      border: 1px solid #798087;
      box-shadow: none;
    }
  }

  &:disabled {
    background-color: rgba(144, 152, 161, 0.7);

    &.borderless {
      color: rgba(144, 152, 161, 0.7);
      background-color: rgba(144, 152, 161, 0.7);
    }

    &.ghost {
      color: rgba(144, 152, 161, 0.7);
      background-color: unset;
      border: 1px solid rgba(144, 152, 161, 0.7);
      box-shadow: none;
    }
  }
}

/*** End Neutral color ***/
