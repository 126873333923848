.block {
  height: 100%;
  min-height: 190px;
  padding: 16px 19px;
  background-color: #454545;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    h3 {
      color: #fff;
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 0.325rem;
      letter-spacing: 0;
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .data {
      display: flex;
      margin-right: 1rem;
      margin-bottom: 1.25rem;

      h5 {
        margin-bottom: 5px;
        color: #fff;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 15px;
        letter-spacing: 0;
      }

      p {
        color: #fff;
        font-size: 0.75rem;
        line-height: 15px;
        letter-spacing: 0;
      }
    }
  }
}

.block_secondary {
  height: 100%;
  min-height: 190px;
  padding: 16px 19px;
  background-color: #fff;
  border: 1px solid #d8d8d8;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    h3 {
      color: rgb(0, 0, 0);
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 0.325rem;
      letter-spacing: 0;
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .data {
      display: flex;
      margin-right: 1rem;
      margin-bottom: 1.25rem;

      h5 {
        margin-bottom: 5px;
        color: rgb(0, 0, 0);
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 15px;
        letter-spacing: 0;
      }

      p {
        color: rgb(0, 0, 0);
        font-size: 0.75rem;
        line-height: 15px;
        letter-spacing: 0;
      }
    }
  }
}
