@import "tailwindcss/base";
@import "tailwindcss/components";
@import "common";
@import "button";
@import "tailwindcss/utilities";
@import "actiontext";

:root {
  --gl-star-color: #f2c94c;
  --gl-star-size: 2rem;

  font-size: 14px;
}

.object_fit_contain_video div video {
  object-fit: contain !important;
  background-color: #212121;
}

.bg-blue-dark {
  background-color: #0c2340;
}

.font-size-45 {
  font-size: 45px;
}

.font-size-25 {
  font-size: 25px;
}

.bg-dark-transparent {
  background: rgba(20, 25, 31, 0.75);
}

.e4z-star {
  fill: unset !important;
  stroke: #f2c94c !important;
}

.gl-active .e4z-star {
  fill: #f2c94c !important;
}

.w-55 {
  width: 55%;
}

.shadow-gray {
  box-shadow: 0 4px 30px rgba(12, 35, 64, 0.05);
}

.border-light-gray {
  border-color: rgba(75, 80, 109, 0.4);
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  animation-name: progression;
  animation-duration: 2s;
}

.progression-bar-file-text {
  animation-name: progression_text;
  animation-duration: 2s;
}

.progress-bar-remove {
  cursor: none;
  opacity: 0;
  animation-name: progression_remove;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.flex-start {
  align-self: flex-start;
}

@keyframes progression {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes progression_text {
  from {
    color: #000;
  }

  to {
    color: #fff;
  }
}

@keyframes progression_remove {
  99% {
    cursor: unset;
    opacity: 0;
  }

  100% {
    cursor: pointer;
    opacity: 1;
  }
}
