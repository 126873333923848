.lds_dual_ring {
  display: inline-block;

  @apply w-10 h-10;
}

.lds_dual_ring::after {
  display: block;
  animation: lds_dual_ring 1.2s linear infinite;
  content: " ";

  @apply w-full h-full;
  @apply border-4 border-blue rounded-full;
  @apply border-r-transparent border-l-transparent;
}

@keyframes lds_dual_ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
