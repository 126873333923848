.grid_wrapper {
  @apply grid grid-rows-3 grid-cols-4 h-full gap-2;

  .viewer {
    @apply col-span-2 row-span-2;
  }

  .identity {
    @apply col-span-2 row-span-1;
  }
}

.h70 {
  height: 70%;
}

.roundedCard {
  position: relative;
  height: 100%;
}

.imgBlockBig {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
}

.imgBlockSmall {
  position: absolute;
  top: 0;
  right: 0;
  width: 13%;
}
