.participantTooltip {
  width: 700px;
}

.hostTooltip {
  width: 200px;
  pointer-events: auto;
}

.absoluteTooltip a {
  @apply text-blue;
}

.tooltipTitle {
  margin-bottom: 18px;
  font-weight: bold;
}

.text {
  font-size: 13px;
}
