.label {
  display: flex;
  padding: 3px 16px;
  color: #fff;
  font-size: 12px;
  background-color: #454545;
  border-radius: 11.5px;

  span {
    margin-left: 10px;
  }
}
