abbr[title] {
  text-decoration: none !important;
}

h1 {
  font-size: 2em;
}

.form {
  label {
    @apply block tracking-wide text-gray-700 text-xs font-bold my-2;
  }

  input {
    @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;

    &:focus {
      @apply outline-none;
    }
  }
}

.form-input {
  @apply appearance-none border w-full py-2 px-3 text-gray-700 leading-tight mt-1;

  &:disabled {
    @apply outline-none cursor-not-allowed;
  }

  &:focus {
    @apply outline-none;
  }
}

.form-select {
  @apply shadow rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.flash {
  &.alert {
    @apply bg-red-100 border-red-400 text-red-700;
  }

  &.notice {
    @apply bg-blue-100 border-blue-400 text-blue-700;
  }
}

::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 2px;
  cursor: pointer;
}
